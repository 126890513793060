<template>

    <BaseContainer>

        <header class="mb-6">

            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.activities')"></h1>
      
            <Breadcrumbs :current="Route.ACTIVITY_INDEX"/>

        </header>

        <DataTable
            v-model:page="page"
            id="activities"
            :columns="columns"
            :rows="rows"
            :transform="transform"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"
            @view="view"
            @edit="edit"
            @destroy="(id, args) => destroy(id, args)">

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.activity-create') }}</BaseButton>
            </template>

            <template #client="{ row, value }">
                <RouterLink v-if="row.client_id" class="text-primary" :to="{ name: Route.CLIENT_VIEW, params: { id: row.client_id } }">{{ value }}</RouterLink>
            </template>

            <template #course="{ row, value }">
                <RouterLink v-if="row.course_id" class="text-primary" :to="{ name: Route.COURSE_VIEW, params: { id: row.course_id } }">{{ value }}</RouterLink>
            </template>

            <!--template #sector="{ row, value }">
                <button v-if="row.client?.sector" class="text-primary" @click.prevent="$refs.sectors.edit(row.client.sector_id)">{{ value }}</button>
            </template-->

            <template #dates_coverage="{ row, value }">
                <i class="mr-2" :class="row.dates_coverage >= 100.0 ? 'fa-duotone fa-circle-check text-success' : 'fa-duotone fa-circle-exclamation text-danger'"></i>
                <span>{{ value }}</span>
            </template>
            
            <template #users="{ row, value }">
                <ul class="d-flex comma-separated m-0 p-0">
                    <li v-for="user in value" :key="user.id" class="separated">
                        <RouterLink v-if="'employee' === user.role" :to="{ name: Route.EMPLOYEE_VIEW, params: { id: user.id } }">
                            <span class="text-primary">{{ user.name }}</span>
                        </RouterLink>
                        <RouterLink v-if="'supplier' === user.role" :to="{ name: Route.SUPPLIER_VIEW, params: { id: user.id } }">
                            <span class="text-primary">{{ user.name }}</span>
                        </RouterLink>
                    </li>
                </ul>
            </template>

            <template #actionsAppend="{ row }">
                <button class="flex items-center justify-center w-8 h-8 hover:bg-action-edit text-action-edit hover:text-white focus:ring-2 focus:ring-offset-2 focus:ring-action-edit focus:outline-none" @click.prevent="clone(row.id)">
                    <i class="fa-solid fa-clone"></i>
                </button>
            </template>

        </DataTable>

        <BaseDrawer :title="$t($refs.sessions?.mode === 'edit' ? 'labels.session-edit' : 'labels.session-create')">
            <SessionForm ref="sessions"/>
        </BaseDrawer>

        <BaseDrawer :title="$t($refs.sectors?.mode === 'edit' ? 'labels.sector-edit' : 'labels.sector-create')">
            <SectorForm ref="sectors"/>
        </BaseDrawer>

        <BaseDrawer :title="$t($refs.grades?.mode === 'edit' ? 'labels.grade-edit' : 'labels.grade-create')">
            <GradeForm ref="grades"/>
        </BaseDrawer>

    </BaseContainer>

</template>

<style scoped>
.comma-separated a + a:before, .comma-separated .separated + .separated:before {
    color: inherit;
    content: ", ";
}
</style>

<script setup>    
    import { activities, activityGroups } from '@/api'
    import Route from '@/constants/route'
    import ToastType from '@/constants/toast-type'
    import { date } from '@/composables/dates'
    import { useActivityStatuses, useActivityTypes, useI18n, useLanguages, useTrans } from '@/composables/i18n'
    import { useModelIndex } from '@/composables/model-ui'    
    import { toast } from '@/composables/toast'
    import { useActivityStore } from '@/store/model'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useActivityStore()
    const router = useRouter()

    const languages = useLanguages().map(item => ({ value: item.id, text: item.text }))
    const statuses = useActivityStatuses().map(item => ({ value: item.id, text: item.text }))
    const types = useActivityTypes().map(type => ({ value: type.id, text: type.text }))

    const { rows, from, to, total, page, pages, loading, search, filter, list } = useModelIndex('activity', store, {
        created: t('message.activity-created'),
        updated: t('message.activity-updated'),
        destroyed: t('message.activity-destroyed')
    })

    const columns = computed(() => [
        { id: 'id', column: t('labels.id'), text: t('table.activities.id') },
        { id: 'course', column: t('labels.course'), text: t('table.activities.course') },
        { id: 'client', column: t('labels.client'), text: t('table.activities.client') },
        { id: 'grades', column: t('labels.grades'),  text: t('table.activities.grades') },
        { id: 'categories', column: t('labels.categories'), text: t('table.activities.categories') },
        { id: 'status', column: t('labels.status'), text: t('table.activities.status'), options: statuses },
        { id: 'notes_status', column: t('labels.notes-status'), text: t('table.activities.notes-status') },
        { id: 'type', column: t('labels.type'), text: t('table.activities.type'), options: types },
        { id: 'language', column: t('labels.language'), text: t('table.activities.language'), options: languages },
        { id: 'representative', column: t('labels.representative'), text: t('table.activities.representative') },
        { id: 'location', column: t('labels.location'), text: t('table.activities.location') },
        { id: 'invoice_number', column: t('labels.invoice-number'), text: t('table.activities.invoice-number') },
        { id: 'notes_public', column: t('labels.notes-public'), text: t('table.activities.notes-public') },
        { id: 'notes_private', column: t('labels.notes-private'), text: t('table.activities.notes-private') },
        { id: 'notes_employee', column: t('labels.notes-employee'), text: t('table.activities.notes-employee') },
        { id: 'session', column: t('labels.session'), text: t('table.activities.session') },
        { id: 'price', column: t('labels.price'), text: t('table.activities.price') },
        { id: 'week_days', column: t('labels.week-days'), text: t('table.activities.week-days') },
        { id: 'date_start', column: t('labels.date-start'), text: t('table.activities.date-start') },
        { id: 'date_end', column: t('labels.date-end'), text: t('table.activities.date-end') },
        { id: 'time_start', column: t('labels.time-start'), text: t('table.activities.time-start') },
        { id: 'time_end', column: t('labels.time-end'), text: t('table.activities.time-end') },
        { id: 'registrations', column: t('labels.registrations'), text: t('table.activities.registrations') },
        { id: 'registrations_active', column: t('labels.registrations-active'), text: t('table.activities.registrations-active') },
        { id: 'registrations_waiting', column: t('labels.registrations-waiting'), text: t('table.activities.registrations-waiting') },
        { id: 'registration_start', column: t('labels.registration-start'), text: t('table.activities.registration-start') },
        { id: 'registration_end', column: t('labels.registration-end'), text: t('table.activities.registration-end') },
        { id: 'registration_maximum', column: t('labels.registration-maximum'), text: t('table.activities.registration-maximum') },
        { id: 'users', column: t('labels.employees'), text: t('table.activities.employees') },
        { id: 'dates_total', column: t('labels.dates'), text: t('table.activities.dates') },
        { id: 'dates_coverage', column: t('labels.dates-coverage'), text: t('table.activities.dates-coverage') },
        { id: 'dates_assigned', column: t('labels.dates-assigned'), text: t('table.activities.dates-assigned') },
        { id: 'dates_unassigned', column: t('labels.dates-unassigned'), text: t('table.activities.dates-unassigned') },
        { id: 'sector', column: t('labels.sector'), text: t('table.activities.sector') },
        { id: 'created_at', column: t('labels.created'), text: t('table.activities.created') },
        { id: 'updated_at', column: t('labels.updated'), text: t('table.activities.updated') },
    ])

    const form = ref()
    const create = () => router.push({ name: Route.ACTIVITY_CREATE })
    const edit = (id) => router.push({ name: Route.ACTIVITY_EDIT, params: { id } })
    const view = (id) => router.push({ name: Route.ACTIVITY_VIEW, params: { id } })

    const destroy = async (id, data) => {
        try {
            await activityGroups.destroy(data.group_id)
            await store.refresh()

            toast(ToastType.SUCCESS, t('message.activity-destroyed'))
        } catch (error) {

        }
    }

    function getExcerpt(str, limit) {
        var excerpt = ''

        if (str && limit) {
            excerpt = str.replace(/(<([^>]+)>)/gi, "")
            excerpt = excerpt.substr(0, excerpt.lastIndexOf(' ', limit)) + '...'
        }

        return excerpt
    }

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
    function transform (column, row) {        
        switch (column) {
            case 'client': return row.client?.name || ''
            case 'course': return row.course ? trans({ en: row.course.name_en, fr: row.course.name_fr }) : ''
            case 'dates': return row.dates?.length.toFixed(0) || 0
            case 'users': return users(row)
            case 'grades': return list(row.grades.map(grade => trans({ en: grade.name_en, fr: grade.name_fr })))
            case 'categories': return list(row.categories.map(category => trans({ en: category.name_en, fr: category.name_fr })))
            case 'name': return trans({ en: row.name_en, fr: row. name_fr })
            case 'status': return row.status ? t(`enum.activity-status.${row.status}`) : ''
            case 'type': return row.type ? t(`enum.activity-type.${row.type}`) : ''
            case 'coverage': return row.coverage.toFixed(0) + '%'
            case 'sector': return row.client?.sector?.name
            case 'registration_start': return row.registration_start ? date(row.registration_start) : ''
            case 'registration_end': return row.registration_end ? date(row.registration_end) : ''
            case 'date_start': return row.date_start ? date(row.date_start) : ''
            case 'date_end': return row.date_end ? date(row.date_end) : ''
            case 'dates_coverage': return parseFloat(row.dates_coverage).toFixed(2) + '%'
            case 'notes_public' : return getExcerpt(row.notes_public, 30)
            case 'notes_private' : return getExcerpt(row.notes_private, 30)
            case 'notes_employee' : return getExcerpt(row.notes_employee, 30)
            case 'notes_status' : return getExcerpt(row.notes_status, 30)
            case 'language': return row.language ? t(`enum.language.${row.language}`) : ''
        }

        return column in row ? row[column] : ''
    }

    /**
     * Map users list.
     * 
     * @param {object} row 
     * @return {string}
     */
    function users(row) {
        return row.users
            ?.map(user => ({ id: user.id, role: user.role, name: name(user) }))
            ?.sort((a, b) => {
                const nameA = a.name?.toLowerCase?.()
                const nameB = b.name?.toLowerCase?.()

                return nameA?.localeCompare?.(nameB)
            })
    }

    /**
     * Get the user display name.
     * 
     * @param {object} user 
     * @return {string}
     */
     function name(user) {
        if ('supplier' === user.role) {
            return user.name
        }

        return `${user.first_name} ${user.last_name}`
    }

    /**
     * Clone the specified id.
     * 
     * @param {*} id 
     */
    async function clone(id) {
        const response = await activities.clone(id)
        const rows = await store.refresh()

        toast(ToastType.SUCCESS, t('message.activity-cloned'))
        router.push({ name: Route.ACTIVITY_EDIT, params: { id: response.data.data.id } })
    }
</script>

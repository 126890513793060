<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">

            <div class="grid gap-6">

                <BaseInputGroup :label="$t('labels.child')" :error="form?.error('child_id')">
                    <BaseListbox
                        ref="inputChild"
                        v-model="payload.child_id"
                        :options="children"
                        :value="option => option.id"
                        :label="option => `${option.first_name} ${option.last_name}`"/>
                </BaseInputGroup>

                <BaseInputGroup :label="$t('labels.group')" :error="form?.error('activity_group_id')">
                    <BaseListbox
                        ref="inputGroup"
                        v-model="payload.activity_group_id"
                        :searchable="true"
                        :options="groups"
                        :value="option => option.group"
                        :label="option => option.name">
                        <template #label="{ option }">
                            <div class="d-flex flex-col w-100">
                                <span class="d-block font-medium">{{ option.internal.activity }}-{{ option.internal.client_name }}: {{ option.label }}</span>
                                <span class="d-block fs-7 text-muted">{{ option.internal.dates }}</span>
                            </div>
                        </template>
                    </BaseListbox>
                </BaseInputGroup>

                <BaseInputGroup :label="$t('labels.options')">
                    <BaseListbox
                        ref="inputOptions"
                        v-model="payload.options"
                        :multiple="true"
                        :options="options"
                        :value="option => option.id"
                        :label="option => trans({ en: option.name_en, fr: option.name_fr })"/>
                </BaseInputGroup>

                <BaseInputGroup :label="$t('labels.status')">
                    <BaseListbox
                        v-model="payload.status"
                        :options="statuses"/>
                </BaseInputGroup>

                <div>
                    <BaseButton :disabled="submitting" :loading="submitting">
                        {{ $t(action) }}
                    </BaseButton>
                </div>

            </div>

        </LoadingOverlay>

    </BaseForm>

</template>

<script setup>
    import _ from 'lodash'
    import { useI18n, useTrans } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useClientStore, useRegistrationStore } from '@/store/model'
    import ToastType from '@/constants/toast-type'
    import { isNullish } from '@/composables/utility'
import { match } from 'assert'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useRegistrationStore()
    const clientStore = useClientStore()

    const messages = computed(() => ({
        'created': {
            message: t('message.registration-created'),
            type: ToastType.SUCCESS
        },
        'updated': {
            message: t('message.registration-updated'),
            type: ToastType.SUCCESS
        },
        'updated-existing': {
            message: t('message.registration-updated-existing'),
            type: ToastType.WARNING
        },
        'updated-manual': {
            message: t('message.registration-updated-manual'),
            type: ToastType.WARNING
        },
        'destroyed': {
            message: t('message.registration-destroyed'),
            type: ToastType.SUCCESS
        }
    }))

    const statuses = computed(() => ([
        { id: 'active', text: t('enum.registration-status.active') },
        { id: 'inactive', text: t('enum.registration-status.inactive') },
        { id: 'waiting', text: t('enum.registration-status.waiting') },
    ]))

    // The parent to filter if applicable.
    const userId = ref()

    // Input references.
    const inputGroup = ref()
    const inputChild = ref()
    const inputOptions = ref()

    // Determine if clients are loading.
    const clientsLoading = ref(false)

    // Use the model form composable.
    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({
        store: store,
        messages: messages,
        editing: (data) => {
            payload.value.options = data?.options?.map(option => option.id)
        }
    })

    // Determine the action button label.
    const action = computed(() => id.value  ? 'labels.submit-registration-edit' : 'labels.submit-registration-create')

    // Compute the clients from the store.
    const clients = computed(() => clientStore.rows)

    // Compute the child options from the clients.
    const children = computed(() => {
        // Flatten the children from the client data.
        let results = _.reduce(clients.value, (result, client) => {
            return result.concat(client.children)
        }, [])

        // Filter the children by the parent if applicable.
        if (!isNullish(userId.value)) {
            results = results.filter(child => child.user_id === userId.value)
        }

        // Sort the childer alphabetically.
        return _.sortBy(results, ['first_name', 'last_name'])
    })

    // Compute the groups from the clients based on the selected child.
    const groups = computed(() => {
        let results = []

        clients.value.forEach(client => {
            client.activities?.forEach(activity => {
                activity.groups?.forEach(group => {
                    const dates = group.dates
                        .map(d => dayjs(d.date))
                        .sort((a, b) => a.isAfter(b) ? 1 : -1)
                        .map(d => d.format('YYYY-MM-DD'))

                    if (dates.length > 2) {
                        dates.splice(1, dates.length - 2)
                    }

                    results.push({
                        client: client.id,
                        client_name: client.name,
                        activity: activity.id,
                        group: group.id,
                        name: trans({ en: activity.course?.name_en, fr: activity.course?.name_fr }),
                        dates: dates.join(' - '),
                        children: _.map(client.children, 'id'),
                        options: activity.course?.options || [],
                    })
                })
            })
        })

        results = results.filter(group => group.children.includes(payload.value.child_id))

        return _.sortBy(results, ['name'])
    })

    // Compute the options from the groups based on the selected group.
    const options = computed(() => {
        const group = groups.value?.find(group => group.group === payload?.value.activity_group_id)

        if (!group) {
            return []
        }

        return group.options
    })

    watch(() => payload.value?.child?.client_id, (value) => {
        clientsLoading.value = true
        clientStore.all([value]).then(() => {
            clientsLoading.value = false
        })
    }, { deep: true })

    watch(() => payload.value?.order?.user_id, (value) => {
        userId.value = value
    }, { deep: true })

    // Force refresh input groups when the groups, children, or options change.
    watch(() => groups, () => nextTick(() => {
        inputGroup.value?.refresh()
    }), { deep: true })
    watch(() => children, () => nextTick(() => {
        inputChild.value?.refresh()
        inputGroup.value?.refresh()
    }), { deep: true })
    watch(() => options, () => nextTick(() => {
        inputOptions.value?.refresh()
    }), { deep: true })

    // Reset the activity group and options if the child changes and is not applicable to the selected group.
    watch(() => payload.value.child_id, (newValue, oldValue) => {
        if (!clientsLoading.value) {
            const matchingGroups = groups.value?.filter(group => group.group == payload.value.activity_group_id)

            if (!matchingGroups.length) {
                payload.value.activity_group_id = null
                payload.value.options = []
            }
        }
    }, { deep: true })

    // Reset the options if the group changes.
    watch(() => payload.value.activity_group_id, (newValue, oldValue) => {
       const group = groups.value?.find(group => group.group == newValue)
       const matchingOptions = group?.options?.filter(option => payload.value.options.includes(option.id))

        if (!matchingOptions || !matchingOptions.length) {
            payload.value.options = []
        }
    }, { deep: true })

    defineExpose({ mode, create, edit })
</script>

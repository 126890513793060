<template>

    <LoadingOverlay :loading="loading">

        <BaseCard>

            <template #header>
                <span class="card-title fs-6 fw-bold">{{ $t('labels.general') }}</span>

                <!--begin::Close-->
                <button v-if="props.modal" type="button"
                    class="position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-dark" data-bs-dismiss="modal">
                    <i class="ki-duotone ki-cross fs-1"><span class="path1"></span><span class="path2"></span></i>
                </button>
                <!--end::Close-->
            </template>

            <div class="flex flex-col">
                <div v-if="isAdmin" class="py-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.activity') }}</dt>
                    <dd class="block">
                        <div><a href="#" @click.prevent="editActivity(model.id)">{{ model.id }}</a></div>
                    </dd>
                </div>
                <div class="pb-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.course') }}</dt>
                    <dd class="block">
                        <a v-if="model.course && isAdmin" href="#" @click.prevent="editCourse(model.course.id)"
                            class="text-primary">
                            {{ trans({ en: model.course.name_en, fr: model.course.name_fr }) }}
                        </a>
                        <button v-if="model.course && !isAdmin && authStore.impersonating" class="text-primary"
                            @click.prevent="onEdit()">
                            {{ trans({ en: model.course.name_en, fr: model.course.name_fr }) }}
                        </button>
                        <div v-if="model.course && !isAdmin && !authStore.impersonating">
                            {{ trans({ en: model.course.name_en, fr: model.course.name_fr }) }}
                        </div>
                    </dd>
                </div>
                <div class="py-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.client-name') }}</dt>
                    <dd class="block">
                        <a v-if="model.client && isAdmin" href="#" @click.prevent="editClient(model.client.id)"
                            class="text-primary">
                            {{ model.client.name }}
                        </a>
                        <div v-if="model.client && !isAdmin">{{ model.client.name }}</div>
                    </dd>
                </div>
                <div class="py-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.client-address') }}</dt>
                    <dd class="block">
                        <div v-if="model.client">{{ address(model.client) }}</div>
                    </dd>
                </div>
                <div class="py-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.grade') }}</dt>
                    <dd class="block">
                        <ul class="list-disc pl-4">
                            <li v-for="grade in model?.grades" :key="grade.id">
                                {{ trans({ en: grade.name_en, fr: grade.name_fr }) }}
                            </li>
                        </ul>
                    </dd>
                </div>
                <div class="py-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.type') }}</dt>
                    <dd class="block">{{ model?.type ? $t(`enum.activity-type.${model.type}`) : '-' }}</dd>
                </div>
                <div class="py-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.language') }}</dt>
                    <dd class="block">{{ model?.language ? $t(`enum.language.${model.language}`) : '-' }}</dd>
                </div>
                <div class="py-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.representative') }}</dt>
                    <dd class="block">{{ model?.representative || '-' }}</dd>
                </div>
                <div class="py-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.local') }}</dt>
                    <dd class="block">{{ model?.location || '-' }}</dd>
                </div>
                <div v-if="isAdmin" class="pt-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.invoice-number') }}</dt>
                    <dd class="block">{{ model?.invoice_number || '-' }}</dd>
                </div>
                <div class="pt-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.notes-public') }}</dt>
                    <dd class="block" v-if="model?.notes_public" v-html="model?.notes_public"></dd>
                    <dd class="block" v-if="!model?.notes_public">-</dd>
                </div>
                <div v-if="isAdmin" class="pt-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.notes-private') }}</dt>
                    <dd class="block" v-if="model?.notes_private" v-html="model?.notes_private"></dd>
                    <dd class="block" v-if="!model?.notes_private">-</dd>
                </div>
                <div class="pt-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.notes-employee') }}</dt>
                    <dd class="block" v-if="model?.notes_employee" v-html="model?.notes_employee"></dd>
                    <dd class="block" v-if="!model?.notes_employee">-</dd>
                </div>
            </div>

        </BaseCard>

    </LoadingOverlay>

    <LoadingOverlay :loading="loading">

        <BaseCard>

            <template #header>
                <span class="card-title fs-6 fw-bold">{{ $t('labels.status') }}</span>
            </template>

            <div class="flex flex-col">
                <div class="pb-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.status') }}</dt>
                    <dd class="block">{{ model?.status ? $t(`enum.activity-status.${model.status}`) : '-' }}</dd>
                </div>
                <div v-if="isAdmin" class="py-3 border-t border-gray-200 first:border-none">
                    <dt class="block font-semibold mb-2">{{ $t('labels.notes-status') }}</dt>
                    <dd class="block">{{ model?.notes_status || '-' }}</dd>
                </div>
            </div>

        </BaseCard>

    </LoadingOverlay>

    <LoadingOverlay :loading="loading">

        <BaseCard>

            <template #header>
                <span class="card-title fs-6 fw-bold">{{ $t('labels.schedule') }}</span>
            </template>

            <div class="flex flex-col gap-6">
                <BaseCard v-for="(group, indexGroup) in model?.groups" :key="group.id" :shadow="false">
                    <template #header>
                        <div class="cart-title fs-6 fw-bold pb-5">
                            {{ (indexGroup == 0) ? $t('labels.base') : $t('labels.extension') }}
                        </div>
                    </template>
                    <div class="pb-3 border-t border-gray-200 first:border-none">
                        <div class="block font-semibold mb-2">{{ $t('labels.dates') }}</div>
                        <div class="block">
                            <ul class="list-disc pl-4">
                                <li v-for="d in group?.dates" :key="d.id">{{ date(d.date) }}</li>
                            </ul>
                        </div>
                        <div class="block font-semibold mb-2">{{ $t('labels.dates-without-course') }}</div>
                        <div class="block">
                            <ul class="list-disc pl-4">
                                <li v-for="dx in group?.dates_excluded" :key="dx.id">{{ date(dx.date) }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="py-3 border-t border-gray-200 first:border-none">
                        <div class="block font-semibold mb-2">{{ $t('labels.session') }}</div>
                        <div class="block">{{ group?.session?.name || '-' }}</div>
                    </div>
                    <div v-if="isAdmin" class="py-3 border-t border-gray-200 first:border-none">
                        <div class="block font-semibold mb-2">{{ $t('labels.price') }}</div>
                        <div class="block">{{ group?.price + '$' || '-' }}</div>
                    </div>
                    <div v-if="isAdmin" class="py-3 border-t border-gray-200 first:border-none">
                        <div class="block font-semibold mb-2">{{ $t('labels.registration-start') }}</div>
                        <div class="block">{{ date(group?.registration_start) || '-' }}</div>
                    </div>
                    <div v-if="isAdmin" class="py-3 border-t border-gray-200 first:border-none">
                        <div class="block font-semibold mb-2">{{ $t('labels.registration-end') }}</div>
                        <div class="block">{{ date(group?.registration_end) || '-' }}</div>
                    </div>
                    <div class="py-3 border-t border-gray-200 first:border-none">
                        <div class="block font-semibold mb-2">{{ $t('labels.registration-maximum') }}</div>
                        <div class="block">{{ group?.registration_maximum || '-' }}</div>
                    </div>
                    <div class="py-3 border-t border-gray-200 first:border-none">
                        <div class="block font-semibold mb-2">{{ $t('labels.time-start') }}</div>
                        <div class="block">{{ time(group?.time_start) || '-' }}</div>
                    </div>
                    <div class="py-3 border-t border-gray-200 first:border-none">
                        <div class="block font-semibold mb-2">{{ $t('labels.time-end') }}</div>
                        <div class="block">{{ time(group?.time_end) || '-' }}</div>
                    </div>
                    <div v-if="isAdmin" class="py-3 border-t border-gray-200 first:border-none">
                        <div class="block font-semibold mb-2">{{ $t('labels.waiting-list') }}</div>
                        <div class="block">
                            {{ group?.waiting_list ? $t('enum.boolean.true') : $t('enum.boolean.false') }}
                        </div>
                    </div>
                    <div v-if="isAdmin" class="pt-3 border-t border-gray-200 first:border-none">
                        <div class="block font-semibold mb-2">{{ $t('labels.visible') }}</div>
                        <div class="block">
                            {{ group?.public ? $t('enum.boolean.true') : $t('enum.boolean.false') }}
                        </div>
                    </div>
                </BaseCard>
            </div>

        </BaseCard>

    </LoadingOverlay>

    <LoadingOverlay v-if="model?.users?.length > 0" :loading="loading">

        <BaseCard>

            <template #header>
                <span class="card-title fs-6 fw-bold">{{ $t('labels.employees') }}</span>
            </template>

            <div class="flex flex-col gap-6">
                <BaseCard v-for="user in model?.users" :key="user.id" :shadow="false">
                    <template #header>
                        <RouterLink target="_blank" v-if="user.role == `employee` && isAdmin"
                            class="card-title fs-6 fw-bold link-primary pb-5 m-0 mb-0"
                            :to="{ name: Route.EMPLOYEE_VIEW, params: { id: user.id } }">{{ `${user.first_name}
                            ${user.last_name}` }}</RouterLink>
                        <RouterLink target="_blank" v-if="user.role == `supplier` && isAdmin"
                            class="card-title fs-6 fw-bold link-primary pb-5 m-0 mb-0"
                            :to="{ name: Route.SUPPLIER_VIEW, params: { id: user.id } }">{{ `${user.name}` }}
                        </RouterLink>
                        <div v-if="user.role == `employee` && !isAdmin" class="card-title fs-6 fw-bold pb-5 m-0 mb-0">{{
                            `${user.first_name} ${user.last_name}` }}</div>
                        <div v-if="user.role == `supplier` && !isAdmin" class="card-title fs-6 fw-bold pb-5 m-0 mb-0">{{
                            `${user.name}` }}</div>
                    </template>
                    <div class="block">
                        <div class="pb-5">{{ $t('labels.dates-total') }}: {{ getTotalDatesPerUser(user.id) }}</div>
                        <ul class="list-disc pl-4">
                            <template v-for="group in model?.groups">
                                <li v-for="d in group.dates.filter(d => d.users?.includes(user.id))" :key="d.id">{{
                                    date(d.date) }}</li>
                            </template>
                        </ul>
                    </div>
                </BaseCard>
            </div>

        </BaseCard>

    </LoadingOverlay>

    <LoadingOverlay :loading="loading">

        <BaseCard>

            <template #header>
                <span class="card-title fs-6 fw-bold">{{ $t('labels.files') }}</span>
            </template>

            <ul class="pl-0 report-types report-activities">

                <li class="py-3">
                    <a class="inline-flex items-center hover:text-primary" href="#"
                        @click.prevent="$refs.report?.generate('attendance', { group: attendanceGroupId })">
                        <i class="fa-duotone fa-file-pdf mr-2"></i>{{ $t('enum.report.attendance') }}
                    </a>
                    <BaseListbox v-model="attendanceGroupId" :searchable="false" :clearable="false"
                        :options="groupOptions" />

                </li>
                <li v-if="isAdmin">
                    <a class="inline-flex items-center hover:text-primary" href="#"
                        @click.prevent="$refs.report?.generate('parents', { group: parentGroupId })">
                        <i class="fa-duotone fa-file-pdf mr-2"></i>{{ $t('enum.report.parents') }}
                    </a>
                    <BaseListbox v-model="parentGroupId" :searchable="false" :clearable="false"
                        :options="groupOptions" />

                </li>

            </ul>

        </BaseCard>

    </LoadingOverlay>

    <LoadingOverlay v-if="groups.length > 0" :loading="loading">

        <BaseCard>
            <template #header>
                <span class="card-title fs-6 fw-bold">{{ $t('labels.registrations') }}</span>
            </template>

            <template v-for="group, index in groups" :key="index">

                <div class="flex flex-col gap-6">
                    <BaseCard :shadow="false">
                        <template #header>
                            <div class="cart-title fs-6 fw-bold pb-5">
                                {{ $t('labels.block-information') }}
                            </div>
                        </template>
                        <div class="pb-3 border-t border-gray-200 first:border-none">
                            <div class="block font-semibold mb-2">{{ $t('labels.period') }}</div>
                            <div class="block">{{ group?.dates || '-' }}</div>
                        </div>
                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <div class="block font-semibold mb-2">{{ $t('labels.registrations-active') }}</div>
                            <div class="block">{{ group?.active_registrations_count || '0' }}</div>
                        </div>
                        <div v-if="isAdmin" class="py-3 border-t border-gray-200 first:border-none">
                            <div class="block font-semibold mb-2">{{ $t('labels.registrations-waiting') }}</div>
                            <div class="block">{{ group?.waiting_registrations_count || '0' }}</div>
                        </div>
                    </BaseCard>
                </div>

                <BaseTable class="w-full">

                    <template #header>
                        <BaseTableCell :header="true">{{ $t('labels.child') }}</BaseTableCell>
                        <BaseTableCell :header="true">{{ $t('labels.options') }}</BaseTableCell>
                        <BaseTableCell :header="true">{{ $t('labels.waiting') }}</BaseTableCell>
                        <BaseTableCell v-if="isAdmin" :header="true">{{ $t('labels.actions') }}</BaseTableCell>
                    </template>

                    <BaseTableRow v-for="child, index in group.children" :key="index">

                        <BaseTableCell>
                            <RouterLink v-if="isAdmin" class="text-primary"
                                :to="{ name: Route.CHILD_VIEW, params: { id: child.id } }">{{ child.name }}</RouterLink>
                            <div v-if="!isAdmin">{{ child.name }}</div>
                        </BaseTableCell>
                        <BaseTableCell>{{ child.options.length > 0 ? child.options.join(', ') : '-' }}</BaseTableCell>
                        <BaseTableCell>{{ $t(child.waiting ? 'enum.boolean.true' : 'enum.boolean.false') }}
                        </BaseTableCell>

                        <BaseTableCell v-if="isAdmin" class="text-center first:text-left last:text-right">

                            <div class="inline-flex items-center gap-1">

                                <button
                                    class="flex items-center justify-center w-8 h-8 rounded hover:bg-gray-100 hover:text-primary"
                                    @click.prevent="showRegistration(child.registration_id)">
                                    <i class="fa-duotone fa-file-magnifying-glass"></i>
                                </button>

                                <button
                                    class="flex items-center justify-center w-8 h-8 rounded hover:bg-gray-100 hover:text-primary"
                                    @click.prevent="editRegistration(child.registration_id)">
                                    <i class="fa-duotone fa-pen"></i>
                                </button>

                                <Popconfirm @confirm="destroyRegistration(child.registration_id)">
                                    <button
                                        class="flex items-center justify-center w-8 h-8 rounded hover:bg-gray-100 hover:text-danger">
                                        <i class="fa-duotone fa-trash"></i>
                                    </button>
                                </Popconfirm>

                            </div>

                        </BaseTableCell>
                    </BaseTableRow>

                </BaseTable>

            </template>

        </BaseCard>

    </LoadingOverlay>

    <ReportModal ref="report" />

    <BaseModal :title="$t('labels.activity-edit')" ref="activityModal" fullscreen="true">
        <ActivityForm ref="activityForm" @success="activitySuccess" :modal="true" />
    </BaseModal>
    <BaseModal :title="$t('labels.course-edit')" ref="courseModal" fullscreen="true">
        <CourseForm ref="courseForm" @success="courseSuccess" :modal="true" />
    </BaseModal>
    <BaseModal :title="$t('labels.client-edit')" ref="clientModal" fullscreen="true">
        <ClientForm ref="clientForm" @success="clientSuccess" :modal="true" />
    </BaseModal>
</template>

<script setup>
import { registrations } from '@/api'
import Route from '@/constants/route'
import { date, time } from '@/composables/dates'
import { useTrans } from '@/composables/i18n'
import { useModelView } from '@/composables/model-ui'
import { useActivityStore } from '@/store/model'
import { useAuth } from '@/composables/auth'
import { getEndDate, getStartDate } from '@/composables/activities'
import { useAuthStore } from '@/store/auth'
import { computed, defineEmits } from 'vue'

const authStore = useAuthStore()
const store = useActivityStore()
const trans = useTrans()
const router = useRouter()
const { t } = useI18n()
const { isAdmin } = useAuth()
const attendanceGroupId = ref()
const parentGroupId = ref()

const activityForm = ref()
const activityModal = ref()

const courseForm = ref()
const courseModal = ref()

const clientForm = ref()
const clientModal = ref()

const modal = inject('modal', null)

const emit = defineEmits(['refresh-event']);

const props = defineProps({
    id: String,
    modal: { type: Boolean, default: false }
})

const groupOptions = computed(() => {
    const result = [];
    let lastGroupId = null;

    model?.value?.groups?.forEach((group) => {
        result.push({
            id: group.id,
            text: getStartDate(group) + " - " + getEndDate(group)
        });

        lastGroupId = group.id;

        if (isDateBetween(new Date(getStartDate(group)), new Date(getEndDate(group)))) {
            attendanceGroupId.value = group.id;
            parentGroupId.value = group.id;
            //console.log(group);
            lastGroupId = null;
        }
    });

    if (lastGroupId) {
        attendanceGroupId.value = lastGroupId;
        parentGroupId.value = lastGroupId;
    }

    return result;
});

const { loading, model, index, edit, load } = useModelView({
    store: store,
    routes: {
        edit: Route.ACTIVITY_EDIT,
        index: Route.ACTIVITY_INDEX
    }
})

const groups = computed(() => {
    const results = []

    model?.value?.groups?.forEach(group => {
        if (0 === group.active_registrations.length) {
            return
        }

        const dates = group.dates
            .map(d => dayjs(d.date))
            .sort((a, b) => a.isAfter(b) ? 1 : -1)
            .map(d => d.format('YYYY-MM-DD'))



        if (dates.length > 2) {
            dates.splice(1, dates.length - 2)
        }

        const children = []
        let active_registrations_count = 0
        let waiting_registrations_count = 0

        group.active_registrations.forEach(registration => {
            switch(registration.status) {
                case 'active':
                    active_registrations_count++
                    break
                case 'waiting':
                    waiting_registrations_count++
                    break
            }

            if (isAdmin.value === false && registration.status === 'waiting') {
                return
            }

            children.push({
                registration_id: registration.id,
                id: registration.child.id,
                name: `${registration.child.first_name} ${registration.child.last_name}`,
                options: registration.options.map(option => trans({ en: option.name_en, fr: option.name_fr })),
                waiting: registration.status == 'waiting'
            })
        })

        results.push({
            dates: dates.join(' ' + t('labels.to') + ' '),
            children: children,
            active_registrations_count: active_registrations_count,
            waiting_registrations_count: waiting_registrations_count
        })
    });

    return results
})

watch(() => props.id, (id) => {
    loading.value = true
    load(id)
})

/**
 * Show the specified registration.
 *
 * @param {*} id
 */
function showRegistration(id) {
    router.push({ name: Route.REGISTRATION_VIEW, params: { id } })
}

/**
 * Edit the specified registration.
 *
 * @param {*} id
 */
function editRegistration(id) {
    router.push({ name: Route.REGISTRATION_EDIT, params: { id } })
}

/**
 * Delete the specified registration.
 *
 * @param {*} id
 */
function destroyRegistration(id) {
    registrations.destroy(id).finally(() => {
        load()
    })
}

/**
 * Get the total number of dates for a specific user.
 *
 * @param {string} userId
 * @returns {number}
 */
function getTotalDatesPerUser(userId) {
    let totalDates = 0;
    model?.value?.groups?.forEach(group => {
        totalDates += group.dates.filter(d => d.users?.includes(userId)).length;
    });
    return totalDates;
}

function address(data) {
    let components = []

    if (data.address1) components.push(data.address1)
    if (data.address2) components.push(data.address2)
    if (data.city) components.push(data.city)
    if (data.province) components.push(data.province)
    if (data.postal_code) components.push(data.postal_code)

    return components.join(', ')
}

async function onEdit() {
    await authStore.logout()
    modal.close?.()
    router.push({ name: Route.ACTIVITY_EDIT, params: { id: props.id } })
}
function isDateBetween(startDate, endDate) {
    const today = new Date();

    return startDate <= today && today <= endDate;
}

function editActivity(id) {
    //   modal?.close();
    activityModal.value.open();
    activityForm.value.edit(id);
}
function activitySuccess() {
    load(props.id);
    emit('refresh-event');
    activityModal.value?.close();
}

function editCourse(id) {
    //   modal?.close();
    courseModal.value.open();
    courseForm.value.edit(id);
}
function courseSuccess() {
    load(props.id);
    emit('refresh-event');
    courseModal.value?.close();
}

function editClient(id) {
    //   modal?.close();
    clientModal.value.open();
    clientForm.value.edit(id);
}
function clientSuccess() {
    load(id);
    emit('refresh-event');
    clientModal.value?.close();
}
</script>

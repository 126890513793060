<template>

    <div class="mb-5 mb-lg-10 w-100">

        <div id="kt_app_toolbar" class="app-toolbar  py-3 py-lg-6 ">
            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack container-admin">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{{
                        $t('labels.activities') }}</h1>
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li class="breadcrumb-item text-muted">
                            <RouterLink :to="{ name: Route.HOME }" class="text-muted text-hover-primary">{{
                                $t('component.breadcrumbs.home') }}</RouterLink>
                        </li>
                        <li class="breadcrumb-item"><span class="bullet bg-gray-400 w-5px h-2px"></span></li>
                        <li class="breadcrumb-item text-muted">{{ $t('labels.activities') }}</li>
                    </ul>
                </div>
            </div>
        </div>

        <div id="kt_app_content_container" class="app-container container-xxl container-admin">

            <TabGroup @change="onChangeTab()">

                <TabList class="mb-6" />

                <LoadingOverlay :loading="loading">

                    <TabPanel :title="$t('labels.calendar')">

                        <BaseCard>

                            <template #header>

                                <div class="flex items-stretch -mb-[2px]">
                                    <button class="border-b-[3px] px-6"
                                        :class="!archived ? 'border-primary font-semibold' : 'border-transparent'"
                                        @click.prevent="archived = false">
                                        {{ $t('component.datatable.tab-active') }}</button>
                                    <button class="border-b-[3px] px-6"
                                        :class="archived ? 'border-primary font-semibold' : 'border-transparent'"
                                        @click.prevent="archived = true">
                                        {{ $t('component.datatable.tab-archived') }}
                                    </button>
                                </div>

                            </template>

                            <template v-if="isAdmin" #actions>
                                <BaseButton modifier="light" @click.prevent="resetFilters()">
                                    <i class="fa-duotone fa-arrows-rotate mr-2"></i>{{
                                    $t('component.datatable.filters-reset') }}
                                </BaseButton>
                            </template>

                            <div class="calendar-container">
                                <div v-if="isAdmin" class="calendar-filters">
                                    <BaseTable class="calendar-filters">
                                        <template #header>
                                            <BaseTableCell class="px-4" :header="true">
                                                {{ $t('table.activities.date') }}
                                            </BaseTableCell>
                                            <BaseTableCell class="px-4" :header="true">
                                                {{ $t('table.activities.activity') }}
                                            </BaseTableCell>
                                            <BaseTableCell class="px-4" :header="true">
                                                {{ $t('table.activities.client') }}
                                            </BaseTableCell>
                                            <BaseTableCell v-if="isAdmin" class="px-4" :header="true" :width="180">
                                                {{ $t('table.activities.type') }}
                                            </BaseTableCell>
                                            <BaseTableCell v-if="isAdmin" class="px-4" :header="true">
                                                {{ $t('table.activities.employee') }}
                                            </BaseTableCell>
                                            <BaseTableCell class="px-4" :header="true">
                                                {{ $t('table.activities.time-start') }}
                                            </BaseTableCell>
                                            <BaseTableCell class="px-4" :header="true">
                                                {{ $t('table.activities.time-end') }}
                                            </BaseTableCell>
                                            <BaseTableCell class="px-4" :header="true">
                                                {{ $t('table.activities.rate-standard') }}
                                            </BaseTableCell>
                                            <BaseTableCell class="px-4" :header="true">
                                                {{ $t('table.activities.rate-transportation') }}
                                            </BaseTableCell>
                                            <BaseTableCell class="px-4" :header="true">
                                                {{ $t('table.activities.rate-others') }}
                                            </BaseTableCell>
                                            <BaseTableCell class="px-4" :header="true">
                                                {{ $t('table.activities.registrations') }}
                                            </BaseTableCell>
                                            <BaseTableCell class="px-4" :header="true">
                                                {{ $t('table.activities.registrations-active') }}
                                            </BaseTableCell>
                                            <BaseTableCell v-if="isAdmin" class="px-4" :header="true">
                                                {{ $t('table.activities.registrations-waiting') }}
                                            </BaseTableCell>
                                            <BaseTableCell class="px-4" :header="true">
                                                {{ $t('table.activities.dates') }}
                                            </BaseTableCell>
                                            <BaseTableCell class="px-4" :header="true">
                                                {{ $t('table.activities.status') }}
                                            </BaseTableCell>
                                        </template>

                                        <tr>
                                            <BaseTableCell class="px-0"><input v-model="filters.date"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.date')"></BaseTableCell>
                                            <BaseTableCell class="px-0"><input v-model="filters.activity"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.activity')"></BaseTableCell>
                                            <BaseTableCell class="px-0"><input v-model="filters.client"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.client')"></BaseTableCell>
                                            <BaseTableCell v-if="isAdmin" class="px-0">
                                                <div class="flex relative select-filter">
                                                    <select
                                                        class="outline-none focus:placeholder-transparent appearance-none bg-transparent w-full"
                                                        v-model="filters.type_raw">

                                                        <option v-for="type in types" :key="type" :value="type">
                                                            {{ t(`enum.activity-type.${type}`) }}
                                                        </option>

                                                    </select>

                                                    <button v-if="filters.type_raw" class="text-xs text-gray-400 p-1.5"
                                                        @click.stop.prevent="clear('type_raw')">
                                                        <i class="fa-duotone fa-xmark"></i>
                                                    </button>

                                                    <div v-if="isNull('type_raw')"
                                                        class="flex items-center bg-white absolute inset-x-0 inset-y-0 pointer-events-none text-gray-400">
                                                        {{ $t('table.activities.type') }}
                                                    </div>

                                                </div>
                                            </BaseTableCell>
                                            <BaseTableCell v-if="isAdmin" class="px-0">
                                                <input v-model="filters.employee"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.employee')">
                                            </BaseTableCell>
                                            <BaseTableCell class="px-0">
                                                <input v-model="filters.time_start"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.time-start')">
                                            </BaseTableCell>
                                            <BaseTableCell class="px-0">
                                                <input v-model="filters.time_end"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.time-end')">
                                            </BaseTableCell>
                                            <BaseTableCell class="px-0"><input v-model="filters.rate_standard"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.rate-standard')"></BaseTableCell>
                                            <BaseTableCell class="px-0"><input v-model="filters.rate_transportation"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.rate-transportation')">
                                            </BaseTableCell>
                                            <BaseTableCell class="px-0"><input v-model="filters.rate_others"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.rate-others')"></BaseTableCell>
                                            <BaseTableCell class="px-0"><input v-model="filters.registrations"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.registrations')"></BaseTableCell>
                                            <BaseTableCell class="px-0"><input v-model="filters.registrations_active"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.registrations-active')"></BaseTableCell>
                                            <BaseTableCell v-if="isAdmin" class="px-0"><input v-model="filters.registrations_waiting"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.registrations-waiting')"></BaseTableCell>
                                            <BaseTableCell class="px-0"><input v-model="filters.dates"
                                                    class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                    :placeholder="$t('table.activities.dates')"></BaseTableCell>
                                            <BaseTableCell class="px-0" width="240" overflow="auto">
                                                <div class="relative select-filter">
                                                <BaseListbox
                                                    v-model="filters.status_raw"
                                                    :ajax="false"
                                                    :filter="filters.status_raw"
                                                    :searchable="false"
                                                    :value="option => option.id"
                                                    :label="option => option.text"
                                                    :options="statusesOptions"
                                                    :multiple="true"
                                                    :placeholder="$t('table.activities.status')"
                                                />

                                                <div v-if="isNull('status_raw')"
                                                    class="flex items-center bg-white absolute inset-x-0 inset-y-0 pointer-events-none text-gray-400">
                                                    {{ $t('table.activities.status') }}
                                                </div>
                                            </div>
                                            </BaseTableCell>
                                        </tr>
                                    </BaseTable>
                                </div>

                                <FullCalendar ref="calendar" :options="calendarOptions" />

                                <div class="d-block d-sm-none calendar-mobile-note">{{ $t('labels.calendar-note') }}
                                </div>
                            </div>

                        </BaseCard>

                    </TabPanel>

                    <TabPanel :title="$t('labels.list')">

                        <BaseCard>

                            <template #header>

                                <div class="flex items-stretch -mb-[2px]">
                                    <button class="border-b-[3px] px-6"
                                        :class="!archived ? 'border-primary font-semibold' : 'border-transparent'"
                                        @click.prevent="archived = false">
                                        {{ $t('component.datatable.tab-active') }}</button>
                                    <button class="border-b-[3px] px-6"
                                        :class="archived ? 'border-primary font-semibold' : 'border-transparent'"
                                        @click.prevent="archived = true">
                                        {{ $t('component.datatable.tab-archived') }}
                                    </button>
                                </div>

                            </template>

                            <template #actions>
                                <BaseButton modifier="light" @click.prevent="resetFilters()">
                                    <i class="fa-duotone fa-arrows-rotate mr-2"></i>{{
                                    $t('component.datatable.filters-reset') }}
                                </BaseButton>
                                <BaseButton modifier="light" @click.prevent="$refs.settings?.open()">
                                    <i class="fa-duotone fa-gear mr-2"></i>{{ $t('component.datatable.settings') }}
                                </BaseButton>
                            </template>

                            <div class="fc fc-media-screen fc-direction-ltr fc-theme-standard">
                                <div class="fc-header-toolbar fc-toolbar fc-toolbar-ltr cloned-header"></div>
                            </div>

                            <div class="d-flex flex-column gap-4 py-4">
                                <BaseTable>

                                    <template #header>
                                        <BaseTableCell class="px-4" :header="true">{{ $t('labels.archive') }}
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('date')" href="#">
                                                {{ $t('table.activities.date') }}<i v-if="sort === 'date'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('activity')" href="#">
                                                {{ $t('table.activities.activity') }}<i v-if="sort === 'activity'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('client')" href="#">
                                                {{ $t('table.activities.client') }}<i v-if="sort === 'client'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell v-if="isAdmin" class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('type')" href="#">
                                                {{ $t('table.activities.type') }}<i
                                                    v-if="sort === 'time_start' ||sort === 'time_end' "
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell v-if="isAdmin" class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('employee')" href="#">
                                                {{ $t('table.activities.employee') }}<i v-if="sort === 'time'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('time_start')" href="#">
                                                {{ $t('table.activities.time-start') }}<i v-if="sort === 'time'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('time_end')" href="#">
                                                {{ $t('table.activities.time-end') }}<i v-if="sort === 'time'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('rate_standard')" href="#">
                                                {{ $t('table.activities.rate-standard') }}<i
                                                    v-if="sort === 'rate_standard'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('rate_transportation')" href="#">
                                                {{ $t('table.activities.rate-transportation') }}<i
                                                    v-if="sort === 'rate_transportation'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('rate_others')" href="#">
                                                {{ $t('table.activities.rate-others') }}<i v-if="sort === 'rate_others'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell v-if="isAdmin" class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('registrations')" href="#">
                                                {{ $t('table.activities.registrations') }}<i
                                                    v-if="sort === 'registrations'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('registrations_active')" href="#">
                                                {{ $t('table.activities.registrations-active') }}<i
                                                    v-if="sort === 'registrations_active'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell v-if="isAdmin" class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('registrations_waiting')" href="#">
                                                {{ $t('table.activities.registrations-waiting') }}<i
                                                    v-if="sort === 'registrations_waiting'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('dates')" href="#">
                                                {{ $t('table.activities.dates') }}<i v-if="sort === 'dates'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4" :header="true">
                                            <button class="flex items-center gap-2 p-0 shadow-none border-0"
                                                @click.prevent="onSort('status')" href="#">
                                                {{ $t('table.activities.status') }}<i v-if="sort === 'status'"
                                                    :class="'asc' === order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                            </button>
                                        </BaseTableCell>
                                    </template>

                                    <tr>
                                        <BaseTableCell class="px-4"></BaseTableCell>
                                        <BaseTableCell class="px-0"><input v-model="filters.date"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.date')"></BaseTableCell>
                                        <BaseTableCell class="px-0"><input v-model="filters.activity"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.activity')"></BaseTableCell>
                                        <BaseTableCell class="px-0"><input v-model="filters.client"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.client')"></BaseTableCell>
                                        <BaseTableCell v-if="isAdmin" class="px-0">
                                            <div class="flex relative select-filter">
                                                <select
                                                    class="outline-none focus:placeholder-transparent appearance-none bg-transparent w-full"
                                                    v-model="filters.type_raw">

                                                    <option v-for="type in types" :key="type" :value="type">
                                                        {{ t(`enum.activity-type.${type}`) }}
                                                    </option>

                                                </select>

                                                <button v-if="filters.type_raw" class="text-xs text-gray-400 p-1.5"
                                                    @click.stop.prevent="clear('type_raw')">
                                                    <i class="fa-duotone fa-xmark"></i>
                                                </button>

                                                <div v-if="isNull('type_raw')"
                                                    class="flex items-center bg-white absolute inset-x-0 inset-y-0 pointer-events-none text-gray-400">
                                                    {{ $t('table.activities.type') }}
                                                </div>

                                            </div>
                                        </BaseTableCell>
                                        <BaseTableCell v-if="isAdmin" class="px-0"><input v-model="filters.employee"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.employee')"></BaseTableCell>

                                        <BaseTableCell class="px-0">
                                            <input v-model="filters.time_start"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.time-start')">
                                        </BaseTableCell>
                                        <BaseTableCell class="px-0">
                                            <input v-model="filters.time_end"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.time-end')">
                                        </BaseTableCell>

                                        <BaseTableCell class="px-0"><input v-model="filters.rate_standard"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.rate-standard')"></BaseTableCell>
                                        <BaseTableCell class="px-0"><input v-model="filters.rate_transportation"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.rate-transportation')">
                                        </BaseTableCell>
                                        <BaseTableCell class="px-0"><input v-model="filters.rate_others"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.rate-others')"></BaseTableCell>
                                        <BaseTableCell v-if="isAdmin" class="px-0"><input v-model="filters.registrations"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.registrations')"></BaseTableCell>
                                        <BaseTableCell class="px-0"><input v-model="filters.registrations_active"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.registrations-active')"></BaseTableCell>
                                        <BaseTableCell v-if="isAdmin" class="px-0"><input v-model="filters.registrations_waiting"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.registrations-waiting')"></BaseTableCell>
                                        <BaseTableCell class="px-0"><input v-model="filters.dates"
                                                class="outline-none focus:placeholder-transparent field-search bg-transparent w-full px-4"
                                                :placeholder="$t('table.activities.dates')"></BaseTableCell>
                                        <BaseTableCell class="px-0" width="240" overflow="auto">
                                            <div class="relative select-filter">
                                                <BaseListbox
                                                    v-model="filters.status_raw"
                                                    :ajax="false"
                                                    :filter="filters.status_raw"
                                                    :searchable="false"
                                                    :value="option => option.id"
                                                    :label="option => option.text"
                                                    :options="statusesOptions"
                                                    :multiple="true"
                                                    :placeholder="$t('table.activities.status')"
                                                />

                                                <div v-if="isNull('status_raw')"
                                                    class="flex items-center bg-white absolute inset-x-0 inset-y-0 pointer-events-none text-gray-400">
                                                    {{ $t('table.activities.status') }}
                                                </div>
                                            </div>
                                        </BaseTableCell>
                                    </tr>


                                    <tr v-for="event in records" :key="event.key" :style="rowStyle(event)">
                                        <BaseTableCell class="px-4">
                                            <button v-if="event.archived"
                                                @click.prevent="onArchive(event.pivot_id, false)"><i
                                                    class="fa-solid fa-arrows-rotate"></i></button>
                                            <button v-if="!event.archived"
                                                @click.prevent="onArchive(event.pivot_id, true)"><i
                                                    class="fa-solid fa-box-archive"></i></button>
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4"><a href="#"
                                                @click.prevent="displayActivity({ event: event })">{{ event.date }}</a>
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4">{{ event.activity }}</BaseTableCell>
                                        <BaseTableCell class="px-4">{{ event.title }}</BaseTableCell>
                                        <BaseTableCell v-if="isAdmin" class="px-4">{{ event.type }}</BaseTableCell>
                                        <BaseTableCell v-if="isAdmin" class="px-4">{{ event.employee }}</BaseTableCell>
                                        <BaseTableCell class="px-4">{{ event.time_start }}</BaseTableCell>
                                        <BaseTableCell class="px-4">{{ event.time_end }}</BaseTableCell>

                                        <BaseTableCell class="px-4">{{ event.rate_standard?.toFixed?.(2) || '0.00' }}
                                        </BaseTableCell>
                                        <BaseTableCell class="px-4">{{ event.rate_transportation?.toFixed?.(2) || '0.00'
                                            }}</BaseTableCell>
                                        <BaseTableCell class="px-4">{{ event.rate_others?.toFixed?.(2) || '0.00' }}
                                        </BaseTableCell>
                                        <BaseTableCell v-if="isAdmin" class="px-4">{{ event.registrations }}</BaseTableCell>
                                        <BaseTableCell class="px-4">{{ event.registrations_active }}</BaseTableCell>
                                        <BaseTableCell v-if="isAdmin" class="px-4">{{ event.registrations_waiting }}</BaseTableCell>
                                        <BaseTableCell class="px-4">{{ event.dates }}</BaseTableCell>
                                        <BaseTableCell class="px-4">
                                            <span :class="badgeClasses(event)">{{ event.status }}</span>
                                        </BaseTableCell>
                                    </tr>

                                    <tr v-if="records.length === 0">
                                        <BaseTableCell colspan="7">{{ $t('labels.empty') }}</BaseTableCell>
                                    </tr>

                                </BaseTable>

                                <Paginator v-if="pages > 1" v-model="page" :pages="pages" />

                            </div>

                        </BaseCard>

                    </TabPanel>

                </LoadingOverlay>

            </TabGroup>

        </div>

        <ReportModal ref="report" />

    </div>

    <BaseModal ref="modal">

        <template #content>

            <div class="modal-content modal-activity-schedule">

                <ActivityDisplay :id="activityId" :modal="true" @refresh-event="forceRefresh" />

            </div>

        </template>

    </BaseModal>

    <BaseDrawer ref="settings" :title="$t('labels.settings')">

        <div class="flex flex-col divide-y divide-gray-200 space-y-4 -m-6">

            <div class="p-6">

                <h4 class="mb-4 font-semibold">{{ $t('component.datatable.per-page') }}</h4>

                <BaseListbox v-model="perPage" :clearable="false" :searchable="false" :options="perPageOptions" />

            </div>

        </div>

    </BaseDrawer>

</template>

<script setup>
import "@fullcalendar/core/vdom"
import { filter, first, sortBy } from "lodash"
import Route from '@/constants/route'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list";
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'
import { schedule } from '@/api'
import { useTrans, useLocale, useI18n } from "@/composables/i18n"
import { useAuth } from '@/composables/auth'
import { useAuthStore } from "@/store/auth"
import moment from "moment"

const { isAdmin } = useAuth()

const todayDate = moment().startOf("day");
const TODAY = todayDate.format("YYYY-MM-DD")

const calendar = ref(null)
const events = ref([])
const loading = ref(false)
const modal = ref()
const report = ref()
const activityId = ref(null)
const page = ref(1)
const sort = ref('date')
const order = ref('asc')
const filters = ref(reactive({}))
const perPage = ref(10)
const archived = ref(false)
const boundaries = ref({})
const initialized = ref(false)
const cachedData = ref({})
const observer = ref(null)

const { t } = useI18n()
const { locale } = useLocale()

const perPageOptions = [
    { id: 10, text: '10' },
    { id: 25, text: '25' },
    { id: 50, text: '50' },
    { id: 100, text: '100' }
]

const statuses = [
    'standby',
    'cancelled',
    'confirmed',
    'additional-group'
]
const statusesOptions = [
    { id: 'standby', text: t(`enum.activity-status.standby`) },
    { id: 'cancelled', text: t(`enum.activity-status.cancelled`) },
    { id: 'confirmed', text: t(`enum.activity-status.confirmed`) },
    { id: 'additional-group', text: t(`enum.activity-status.additional-group`) }
]

const statusesSearch = async() => {
    return statusesOptions
}

const types = [
    'none',
    'extracurricular',
    'unique-event',
    'birthday',
    'camp',
    'other'
]

const pages = computed(() => {
    return Math.ceil(filtered.value.length / perPage.value)
})

const filtered = computed(() => {
    const sorted = sortBy(events.value, (entry) => entry[sort.value]).filter(entry => {
        let accept = true

        if (archived.value) {
            accept = accept && entry.archived === true
        } else {
            accept = accept && entry.archived === false
        }

        Object.keys(filters.value).forEach(key => {

            let a = (key === 'status_raw') ? filters.value[key] :filters.value[key].toLowerCase()
            let b = entry?.[key]?.toString?.()?.toLowerCase?.() || ''

            if (!a) {
                return
            }

            if (key.startsWith('rate_')) {
                b = parseFloat(b).toFixed(2)
                accept = accept && b.startsWith(a)
            } else if (key === 'time_start') {
                let filterStartTime = parseInt(a.replace(":", ""));
                let entityStartTime = parseInt(b.replace(":", ""))
                accept = accept && entityStartTime >= filterStartTime;

            } else if (key === 'time_end') {
                let filterEndTime = parseInt(a.replace(":", ""));
                let entityEndTime = parseInt(b.replace(":", ""))
                accept = accept && entityEndTime <= filterEndTime;

            } else if(key==='status_raw') {
                if (a.length === 0) {
                    accept = true; // Accept all statuses if status_raw is empty
                } else {
                    accept = accept && a.includes(b);
                }
            } else {
                accept = accept && b.includes(a)
            }
        })

        return accept
    })

    if (order.value === 'desc') {
        return sorted.reverse()
    }

    return sorted
})

const records = computed(() => {
    const startIndex = perPage.value * (page.value - 1)
    const endIndex = startIndex + perPage.value

    let results = []

    for (let i = startIndex; i < endIndex; i++) {
        if (i >= filtered.value.length) {
            continue
        }

        results.push(filtered.value[i])
    }

    return results
})

const visibleEvents = computed(() => {
    return events.value?.filter(event => archived.value ? event.archived : !event.archived)
})

const displayActivity = (info) => {
    activityId.value = info.event.id
    modal.value.open()
}

const datesSet = (info) => {
    boundaries.value = {
        start: info.start.toISOString().split('T')[0],
        end: info.end.toISOString().split('T')[0]
    }

    if (!initialized.value) {
        refresh()

        if (isAdmin.value) {
            const clonedHeader = document.querySelector('.cloned-header')

            if (clonedHeader && (clonedHeader.innerHTML.length === 0)) {
                const targetElement = document.querySelector('.calendar-container .fc-toolbar')
                syncToolbar(targetElement)
            }
        }

        initialized.value = true
    } else {
        if (isAdmin.value) {
            initialized.value = false
        }
    }
}

const calendarOptions = computed(() => {
    const options = {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialDate: TODAY,
        navLinks: true, // can click day/week names to navigate views
        selectable: true,
        selectMirror: true,
        editable: false,
        dayMaxEvents: 10, // allow "more" link when too many events
        firstDay: 1, // Monday
        locales: [frLocale],
        locale: locale.value,
        events: (isAdmin.value) ? filtered.value : visibleEvents.value,
        eventClick: displayActivity,
        datesSet: datesSet,
    };

    if (isAdmin.value) { // Assuming isAdmin is a reactive property
        options.eventContent = (info) => {
            var eventHtml = [];
            eventHtml.push('<div class="fc-daygrid-event-dot" style="border-color: ' + info.event.borderColor + ';"></div>');
            eventHtml.push('<span class="badge' + ((!info.event.extendedProps.coverage) ? ' blink' : '') + '" style="background-color: ' + info.event.extendedProps.user_color + '; color: ' + getContrastYIQ(info.event.extendedProps.user_color) + '">' + info.event.extendedProps.initials + '</span><div class="fc-event-time">' + info.event.extendedProps.time_start + '</div>');
            eventHtml.push('<div class="fc-event-title">' + info.event.title + '</div>');
            return { html: eventHtml.join('') };
        };
        options.dayCellClassNames = (info) => {
            const formattedDate = moment(info.date).format('YYYY-MM-DD');

            if (cachedData.value[JSON.stringify(boundaries.value)] && Array.isArray(cachedData.value[JSON.stringify(boundaries.value)])) {
                const events = cachedData.value[JSON.stringify(boundaries.value)];
                const eventsOnDay = events.filter(event => event.date === formattedDate);
                if (formattedDate == '2024-06-10') {
                    console.log(eventsOnDay); // This will log an array of all events on '2024-06-10'
                }
                if (eventsOnDay.length > 0) {
                    // You can check each event's coverage here, for example
                    const noCoverage = eventsOnDay.some(event => event.coverage === false);
                    if (noCoverage) {
                        return 'no-coverage';
                    }
                }
            }
        };
    }

    return options;
});

const auth = useAuthStore()
const trans = useTrans()

const syncToolbar = (targetElement) => {
    // Find the element where you want to copy the content
    const clonedHeader = document.querySelector('.cloned-header')
    if (clonedHeader) {
        // Copy the HTML content from the observed element to the target element
        clonedHeader.innerHTML = targetElement.innerHTML

        // Query all buttons within the cloned header
        const clonedButtons = clonedHeader.querySelectorAll('button')
        clonedButtons.forEach((clonedButton, index) => {
            // Assuming buttons can be matched by their index
            const originalButton = targetElement.querySelectorAll('button')[index]
            if (originalButton) {
                // Add click event listener to cloned button
                clonedButton.addEventListener('click', () => {
                    originalButton.click() // Trigger click on the original button
                });
            }
        })
    }
}

onMounted(() => {
    if (isAdmin.value) {
        const targetElement = document.querySelector('.calendar-container .fc-toolbar')
        observer.value = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                //console.log('Mutation detected:', mutation)
                syncToolbar(targetElement)
            })
        })

        const config = { childList: true, subtree: true, characterData: true }
        if (targetElement) {
            observer.value.observe(targetElement, config)
        }

        nextTick().then(() => {
            // Move calendar filters
            const calendarFilters = document.querySelector('.calendar-filters');
            const fcToolbar = document.querySelector('.fc-toolbar');
            if (calendarFilters && fcToolbar) {
                // This moves the calendarFilters to be immediately after the fcToolbar
                fcToolbar.insertAdjacentElement('afterend', calendarFilters);
            }
        })
    }
})

onUnmounted(() => {
    if (isAdmin.value) {
        if (observer.value) {
            observer.value.disconnect()
        }
    }
})

const onChangeTab = () => {
    nextTick(() => {
        calendar.value?.getApi?.()?.render?.()
    })
}

const badgeClasses = (event) => {
    return {
        'badge': true,
        'badge-light': !statuses.includes(event.status_raw),
        'badge-warning': event.status_raw === 'standby',
        'badge-danger': event.status_raw === 'cancelled',
        'badge-success': event.status_raw === 'confirmed',
        'badge-dark': event.status_raw === 'additional-group'
    }
}

const rowStyle = (event) => {
    let result = {}

    switch (event.status_raw) {
        case 'additional-group':
            break

        case 'cancelled':
            result.backgroundColor = 'rgb(216,83,110,0.1)'
            break

        case 'confirmed':
            result.backgroundColor = 'rgba(122,202,141,0.1)'
            break

        case 'standby':
            result.backgroundColor = 'rgba(255,199,0,0.1)'
            break
    }

    return result
}

const forceRefresh = async () => {
    if (cachedData.value[JSON.stringify(boundaries.value)]) {
        delete cachedData.value[JSON.stringify(boundaries.value)]
    }

    await refresh()
}

const refresh = async () => {
    loading.value = true

    try {
        if (isAdmin.value && cachedData.value[JSON.stringify(boundaries.value)]) {
            events.value = cachedData.value[JSON.stringify(boundaries.value)]
        } else {
            const { data } = isAdmin.value ? await schedule.index(boundaries.value) : await schedule.index()

            if (Array.isArray(data)) {
                let results = []

                let statusColorMap = {
                    'confirmed': '#28a745',
                    'standby': '#ffc107',
                    'cancelled': '#dc3545'
                };

                data.forEach(entry => {
                    if (!entry.start || !entry.end) {
                        return
                    }

                    results.push({
                        id: `${entry.id}`,
                        pivot_id: entry.pivot_id,
                        key: entry.key,
                        activity: trans(entry.activity),
                        client: entry.client,
                        title: entry.client,
                        registrations: entry.registrations,
                        registrations_active: entry.registrations_active,
                        registrations_waiting: entry.registrations_waiting,
                        dates: entry.dates,
                        date: entry.date,
                        time_start: entry.time_start,
                        time_end: entry.time_end,
                        time: `${entry.time_start} - ${entry.time_end}`,
                        start: entry.start.replace(' ', 'T'),
                        end: entry.end.replace(' ', 'T'),
                        rate_standard: entry.rate_standard,
                        rate_transportation: entry.rate_transportation,
                        rate_others: entry.rate_others,
                        status_raw: entry.status,
                        status: entry.status ? t(`enum.activity-status.${entry.status}`) : '-',
                        archived: entry.archived,
                        color: statusColorMap[entry.status] || '#000000',
                        user_color: entry.user_color,
                        initials: entry.initials,
                        coverage: entry.coverage,
                        type_raw: entry.type,
                        type: entry.type ? t(`enum.activity-type.${entry.type}`) : '-',
                        employee: entry.employee,
                    })
                })

                events.value = results

                if (isAdmin.value) {
                    cachedData.value[JSON.stringify(boundaries.value)] = results;
                }
            }
        }
    } finally {
        loading.value = false
    }
}

const onSort = (column) => {
    if (sort.value === column) {
        order.value = order.value === 'asc' ? 'desc' : 'asc'
        return
    }

    sort.value = column
    order.value = 'asc'
}

const onArchive = async (id, archived) => {
    loading.value = true

    try {
        await schedule.archive(id, { archived: archived })
        await refresh()
    } catch (error) {

    } finally {
        loading.value = false
    }
}

const resetFilters = () => {
    filters.value = {}
}

const getContrastYIQ = function (hexcolor) {
    if (typeof hexcolor === 'string') {
        // Convert hex color to RGB
        const r = parseInt(hexcolor.substr(1, 2), 16);
        const g = parseInt(hexcolor.substr(3, 2), 16);
        const b = parseInt(hexcolor.substr(5, 2), 16);

        // Calculate relative luminance
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        // Return black or white based on luminance
        return (yiq >= 128) ? '#000000' : '#FFFFFF';
    }
}

function clear(column) {
    delete filters.value[column]
}

function isNull(column) {
    const value = filters.value[column]

    return value === null || typeof (value) === 'undefined'
}
</script>

<style lang="scss">
.fc .fc-button {
    padding: 0.75rem 1.25rem;
    box-shadow: none !important;
    border: 0 !important;
    border-radius: 0.475rem;
    vertical-align: middle;
    font-weight: 500;
    text-transform: capitalize;
}
</style>
